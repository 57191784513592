import React, { useState, useEffect, useMemo } from "react";
import ProjectCard from "../../components/ProjectCard";
import TechnologyCard from "../../components/TechnologyCard";
import { useTranslation } from "react-i18next";
import Api from "../../API";
import Slider from "react-slick";
// import telegram from "./../../assets/social-icons/002-telegram 1.svg";
// import whatsApp from "./../../assets/social-icons/003-whatsapp 1.svg";
// import instagram from "./../../assets/social-icons/005-instagram 1.svg";
// import facebook from "./../../assets/social-icons/001-facebook 1.svg";

import arrow from "./../../assets/arrow.svg";
import "./main.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ServicesSection from "../../modules/Servises-section/ServicesSection";
import { stages } from "./stages.js";
import FormSection from "../../modules/FormSection/FormSection";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer";

function SampleNewNextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className={"sampleNewNextArrow"}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={arrow} alt="arrow" />
    </div>
  );
}
function SampleNewPrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className={"sampleNewPrevArrow"}
      style={{ ...style }}
      onClick={onClick}
    >
      <img src={arrow} alt="arrow" />
    </div>
  );
}

const ourProjectsSettings = {
  focusOnSelect: false,
  dots: false,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  autoplay: false,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  className: "project-slider",
  nextArrow: <SampleNewNextArrow />,
  prevArrow: <SampleNewPrevArrow />,
};

const Main = () => {

  const [projects, setProjects] = useState([]);
  const [technologies, setTechnologies] = useState([]);

  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const api_lang = lang === "ru-RU" ? "ru" : lang === "ru" ? "ru" : "en"
  console.log('asd',process.env.REACT_APP_BASE_URL)
  useEffect(() => {
    Api.getData(`/${api_lang}/api/projects/`)
      .then((res) => setProjects(res.data))
      .catch((e) => console.error(e));

    Api.getData(
      `/${api_lang}/api/technologies/`
    )
      .then((res) => setTechnologies(res.data))
      .catch((e) => console.error(e));
  }, [api_lang]);

  const projectsList = useMemo(
    () => projects?.map((item) => <ProjectCard data={item} key={item.id} />),
    [projects]
  );

  const technologyList = useMemo(
    () =>
      technologies?.map((item) => <TechnologyCard data={item} key={item.id} />),
    [technologies]
  );

  return (
    <>
      <Header/>
      <main className="main">
        <section id="aboutus" className="aboutus container container_width">
          <div className="aboutus__left">
            <h1 className="aboutus__title">{t("develop.title")}</h1>
            <p className="aboutus__desc">{t("develop.subtitle")}</p>
            <div className="contact__div">
              <a href="#contacts">
                <div className="aboutus__btn">{t("develop.btn")}</div>
              </a>
            </div>
          </div>
          <div className="aboutus__right">
            <div className="aboutus__img"></div>
          </div>
        </section>

        <ServicesSection />

        <section className="technologies">
          <div className="container container_width">
            <h3 className="title">{t('technologies.title')}</h3>
            <div className="technologies__cards-cont">
              <div className="technologies__cards">{technologyList}</div>
            </div>
          </div>
        </section>

        <section id="workflows" className=" container container_width stages">
          <h3 className="title">{t('workflows.title')}</h3>
          <div className="stage-line"></div>
          {stages[lang].map((stage, i) => (
            <div key={stage.id} className={`stage ${i % 2 && "stage-left"}`}>
              <div className="stage-number">
                <p>{stage.id}</p>
                <div className="stage-circle"></div>
              </div>
              <div className="stage-info">
                <b>{stage.title}</b>
                <p>{stage.body}</p>
              </div>
            </div>
          ))}
        </section>

        <section id="works" className="projects container container_width">
          <h3 className="title margin-none">{t('projects.title')}</h3>
          <div className="projects__slider">
            <Slider {...ourProjectsSettings}>{projectsList}</Slider>
          </div>
        </section>

        <FormSection />
      </main>
      <Footer/>
    </>
  );
};

export default Main;

//  <div className="stages__all">
//         <div className="stages__left">
//           <img src={stage1} alt="stage" />
//           <div className="stages__text">
//             <p className="stages__title">{t("stages.contract")}</p>
//             <p className="stages__desc">{t("stages.contract-desc")}</p>
//           </div>
//           <img src={stage2} alt="stage" />
//           <div className="stages__text">
//             <p className="stages__title">{t("stages.server")}</p>
//             <p className="stages__desc">{t("stages.server-desc")}</p>
//           </div>
//           <img src={stage3} alt="stage" />
//           <div className="stages__text">
//             <p className="stages__title">{t("stages.test")}</p>
//             <p className="stages__desc">{t("stages.test-desc")}</p>
//           </div>
//           <img src={stage4} alt="stage" />
//         </div>
//         <div className="stages__img"></div>
//         <div className="stages__right">
//           <div className="stages__text">
//             <p className="stages__title">{t("stages.conversation")}</p>
//             <p className="stages__desc">{t("stages.conversation-desc")}</p>
//           </div>
//           <img src={stage5} alt="stage" />
//           <div className="stages__text">
//             <p className="stages__title">{t("stages.design")}</p>
//             <p className="stages__desc">{t("stages.design-desc")}</p>
//           </div>
//           <img src={stage6} alt="stage" />
//           <div className="stages__text">
//             <p className="stages__title">{t("stages.frontend")}</p>
//             <p className="stages__desc">{t("stages.frontend-desc")}</p>
//           </div>
//           <img src={stage7} alt="stage" />
//           <div className="stages__text">
//             <p className="stages__title">{t("stages.project")}</p>
//             <p className="stages__desc">{t("stages.project-desc")}</p>
//           </div>
//         </div>
//       </div>

//  <div className="aboutus__social">
//           <a href="https://www.instagram.com/codify.lab/?hl=ru" className="aboutus__social-item">
//             <img src={instagram} alt="instagram"/>
//           </a>
//           <a href="https://wa.me/996755151920" className="aboutus__social-item">
//             <img src={whatsApp} alt="whatsapp"/>
//           </a>
//           <a href="https://t.me/nigoradautova" className="aboutus__social-item">
//             <img src={telegram} alt="telegram"/>
//           </a>
//           <a href="https://www.facebook.com/codifylab.kg/" className="aboutus__social-item">
//             <img src={facebook} alt="facebook"/>
//           </a>

//         </div>
