import React from 'react';
import './technologyCard.css'

const TechnologyCard = ({data}) => {
  return (
    <div className="technologies__card">
      <div className="technologies__card-img">
        <img
          className="technologies-img"
          src={data.image}
          alt="technology"
        />
      </div>
      <p className="technologies__card-text">{data.title}</p>
    </div>
  );
};

export default TechnologyCard;