export const stages = {
  ru: [
    {
      id: 1,
      title: "Поиск Идеи",
      body: "Когда вы приходите к нам с идеей, мы поможем её сформулировать, а затем приступим к работе.",
    },
    {
      id: 2,
      title: "Бизнес-анализ",
      body: "Мы определяем функции продукта и какие конкретные технологии следует использовать для реализации вашей идеи.",
    },
    {
      id: 3,
      title: "Создание Дизайн-макетов и Прототипирование",
      body: "Когда у нас есть вся необходимая информация, мы создаем прототип, который демонстрирует внешний вид вашего приложения.",
    },
    {
      id: 4,
      title: "Разработка",
      body: "После того как прототип будет рассмотрен и получено согласие, мы начнем разработку.",
    },
    {
      id: 5,
      title: "Тестирование",
      body: "Когда основная часть работы по программированию и дизайну выполнена, мы проводим тщательное тестирование.",
    },
    {
      id: 6,
      title: "Запуск и Поддержка",
      body: "После запуска проекта команда следит за его работой, чтобы обеспечить предполагаемую эффективность.",
    },
  ],
  en: [
    {
      id: 1,
      title: "Idea Discovery",
      body: "When you come to us with an idea, we'll help define it and then get to work.",
    },
    {
      id: 2,
      title: "Business Analysis",
      body: "We identify product features and what specific technologies your idea should be built.",
    },
    {
      id: 3,
      title: "Wireframing & Prototyping",
      body: "When all the necessary data is in hand, we'll create a prototype that expresses the appearance of your app.",
    },
    {
      id: 4,
      title: "Development",
      body: "With the prototype reviewed and agreement obtained, we'll begin the development.",
    },
    {
      id: 5,
      title: "Testing",
      body: "When the bulk of the coding and design work is done, we subject that information to extensive tests.",
    },
    {
      id: 6,
      title: "Launch & Support ",
      body: "When the project is released, the team monitors the project to ensure the intended performance.",
    },
  ]
};
