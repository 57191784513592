import { Route, Switch } from "react-router-dom";
import Main from "./pages/Main";
import NotFoundPage from "./pages/NotFoundPage";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/index";

function App() {
  return (
    <>
      <Switch>
        <Route path="/" exact component={Main} />
        <Route path="*" exact component={NotFoundPage} />
      </Switch>
    </>
  );
}

export default App;
