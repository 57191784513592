export const budget = [
  {
    id: 1,
    title: "< $25K",
  },
  {
    id: 2,
    title: "$25K - $75K",
  },
  {
    id: 3,
    title: "$75K - $150K",
  },
  {
    id: 4,
    title: "> $150K",
  },
  {
    id: 5,
    title: "Other",
  },
];
