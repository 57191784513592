import React from "react";
import { useTranslation } from "react-i18next";
import mail from "./../../assets/mail.svg";
import instagram from "./../../assets/social-icons/005-instagram 1.svg";
import whatsapp from "./../../assets/social-icons/003-whatsapp 1.svg";
import telegram from "./../../assets/social-icons/002-telegram 1.svg";
import facebook from "./../../assets/social-icons/001-facebook 1.svg";
import copyright from "./../../assets/copyright.svg";
import logo from "./../../assets/logo.svg";
import "./footer.css";

const Footer = () => {
  const { t } = useTranslation();
  const y = new Date();
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__inner">
          <div className="footer__column">
            <img className="footer__logo" src={logo} alt="logo" />
          </div>
          <div className="footer__column">
            <p className="footer__title">
              {t("footer.about")}
            </p>
            <a href="#aboutus" className="footer__link">
              {t("footer.aboutUs")}
            </a>
            <a href="#ser" className="footer__link">
              {t("footer.services")}
            </a>
            <a href="#workflows" className="footer__link">
              {t("workflows.title")}
            </a>
            <a href="#works" className="footer__link">
              {t("projects.title")}

            </a>
          </div>
          <div className="footer__column">
            <p className="footer__title">
              {t("footer.more")}
            </p>
            <a href="https://www.codifylab.com/" target={'_blank'} rel={'noreferrer'} className="footer__link">
              Codify Academy
            </a>
            <a href="https://lms.codifylab.com/" target={'_blank'} rel={'noreferrer'} className="footer__link">
              Codify CRM LMS
            </a>
          </div>
          <div className="footer__column">
            <p className="footer__title">{t("footer.contacts")}</p>
            <p className={'footer__mail'}>+996 500 431 430</p>
            <a href="mailto:dev@codifylab.com" target={'_blank'} className="footer__mail">
              <span>
                <img src={mail} alt="mail" />
              </span>
              dev@codifylab.com
            </a>
          </div>
        </div>

        <div className="footer__social">
          <a
            href="https://www.instagram.com/codify.lab/?hl=ru"
            className="footer__social-item"
          >
            <img src={instagram} alt="instagram" />
          </a>
          <a href="https://wa.me/996500431430" className="footer__social-item">
            <img src={whatsapp} alt="whatsapp" />
          </a>
          <a
            href="https://www.linkedin.com/company/codify-lab/"
            className="footer__social-item"
          >
            <img src={telegram} alt="Linkedin" />
          </a>
          <a
            href="https://www.facebook.com/codifylab.kg/"
            className="footer__social-item"
          >
            <img src={facebook} alt="facebook" />
          </a>
        </div>

        <p className="copyright">
          <span>
            <img src={copyright} alt="copyright" />
          </span>
          {`${y.getFullYear()} Codify Lab. All rights reserved.`}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
