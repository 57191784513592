import React from "react";
import logo from "./../../assets/logo.svg";
import close from "./../../assets/close.svg";
import "./sidebar.css";
import { useTranslation } from "react-i18next";

const Sidebar = ({ sidebar, setSidebar }) => {
  const { t } = useTranslation();

  return (
    <div className={sidebar ? "sidebar" : "sidebar inactive"}>
      <div className="sidebar__menu">
        <div className="sidebar__header">
          <img src={logo} alt="logo" />
          <img
            className="sidebar__header-close"
            src={close}
            alt="close"
            onClick={setSidebar}
          />
        </div>
        <div className="sidebar__links">
          <a className="sidebar__links-elem" onClick={setSidebar} href="#ser">
            {t("header.services")}
          </a>
          <a className="sidebar__links-elem" onClick={setSidebar} href="#works">
            {t("header.works")}
          </a>
          <a
            className="sidebar__links-elem"
            onClick={setSidebar}
            href="#workflows"
          >
            Workflows
          </a>
          <a
            className="sidebar__links-elem"
            onClick={setSidebar}
            href="#contacts"
          >
            {t("header.contacts")}
          </a>
        </div>

        <div className="sidebar__socials">
          <p className="sidebar__socials-title">{t("header.socials")}</p>
          <a
            className="sidebar__socials-link"
            href="https://www.facebook.com/codifylab.kg/"
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
          <a
            className="sidebar__socials-link"
            href="https://www.instagram.com/codify.lab/?hl=ru"
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
          <a
            className="sidebar__socials-link"
            target="_blank"
            href="https://www.linkedin.com/company/codify-lab/"
            rel="noreferrer"
          >
            LinkedIn
          </a>
          <a
            className="sidebar__socials-link"
            href="https://wa.me/996755151920"
            target="_blank"
            rel="noreferrer"
          >
            WhatsApp
          </a>
        </div>
      </div>
      <div className="sidebar__bg"></div>
    </div>
  );
};

export default Sidebar;
