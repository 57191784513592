import React from "react";
import "./serviceCard.css";

const ServiceCard = ({ service }) => {
  console.log(service);
  return (
    <div key={service.id} className="service-card">
      <div className="service-card_description">
        <div>
          <h4>{service.title}</h4>
          <p>{service.text}</p>
        </div>
        {/*<a href={service.file} target="_blank" rel="noreferrer">*/}
        {/*  <button className="button">Get presentation</button>*/}
        {/*</a>*/}
      </div>
      <div className="service-card_deliverables">
        <b>Deliverables</b>
        {service.deliverables.map((deliver) => (
          <div key={deliver.id} className="deliverable">
            <img src="./icons/services-check.svg" alt="check" />
            <p>{deliver.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceCard;
