import React from "react";
import { Link } from "react-router-dom";
import "./noteFoundPage.css"
import error from "./../../assets/error.svg";

export default function NotFoundPage() {
  return (
    
    <div className="error__container">
            <div className="robot">
                <img src={error} alt="404" />
            </div>
            <div className="desc">
                <div>
                    <h1 className="error__404">404</h1>
                    <p className="error__desc">Page not found</p>
                </div>
                <Link to="/" >
                    <button className="error__button">To home page</button>
                </Link>
            </div>
    </div>

  )
}



