import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Sidebar from "../Sidebar";
import logo from "./../../assets/logo.svg";
import burger from "./../../assets/burger.svg";
import "./header.css";

const Header = () => {
  const [sidebar, setSidebar] = useState(false);

  const { t, i18n } = useTranslation();

  const languageChange = (language) => {
    i18n.changeLanguage(language);
  };
  const changeSidebar = () => {
    setSidebar(!sidebar);
  };

  return (
    <header className="fixed">
      <div className="header container container_width">
        <Sidebar sidebar={sidebar} setSidebar={changeSidebar} />

        <Link to="/" className="logo">
          <img src={logo} alt="logo" />
        </Link>

        <ul className="header__list">
          <li className="header__list-item">
            <a href="#ser" className="header__list-link">
              {t("header.services")}
            </a>
          </li>
          <li className="header__list-item">
            <a href="#workflows" className="header__list-link">
              {t("workflows.title")}
            </a>
          </li>
          <li className="header__list-item">
            <a href="#works" className="header__list-link">
              {t("projects.title")}
            </a>
          </li>
          <li className="header__list-item">
            <a href="#contacts" className="header__list-link">
              {t("header.contacts")}
            </a>
          </li>
        </ul>

       <div className="header__right">
          {/*<div className="header__btn">*/}
          {/*  <a href="tel:+996755151920">{t('header.callback')}</a>*/}
          {/*</div>*/}

          <div
            className={i18n.language === 'ru' ? 'header__language-active' : 'header__language'}
            onClick={() => languageChange('ru')}
          >RU
          </div>
          <div
            className={i18n.language === 'en' ? 'header__language-active' : 'header__language'}
            onClick={() => languageChange('en')}
          >EN
          </div>
         </div>

        <div className="header__burger" onClick={changeSidebar}>
          <img src={burger} alt="burger" />
        </div>
      </div>
    </header>
  );
};

export default Header;
