import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

const http = axios.create({
  baseURL: baseUrl,
})

const Api = {
  getData: (url) => http.get(url),
  postData: (url, body) => http.post(url, body)
}

export default Api;
