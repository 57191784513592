import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../API";
import Arrow from "../../components/arrow/Arrow";
import ServiceCard from "../../components/ServiceCard";
import "./ServicesSection.css";

export default function ServicesSection() {
  const [services, setServices] = useState([]);
  const [activeBtn, setActiveBtn] = useState(0);

  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const api_lang = lang === "ru-RU" ? "ru" : lang === "ru" ? "ru" : "en"

  useEffect(() => {
    Api.getData(`${api_lang}/api/services/`)
      .then((res) => setServices(res.data))
      .catch((e) => console.error(e));
  }, [api_lang]);

  const servicesList = useMemo(
    () => services.map((service) => <ServiceCard service={service} />),
    [services]
  );

  function forPrevArrow() {
    if (activeBtn) {
      setActiveBtn(activeBtn - 1);
    }
  }
  function forNextArrow() {
    if (activeBtn < 2) {
      setActiveBtn(activeBtn + 1);
    }
  }

  return (
    <section id="ser" className="services container container_width">
      <h3 className="title">{t("service.title")}</h3>
      <div className="service_buttons">
        {services.map((service, i) => {
          return (
            <button
              className={`service-btn ${activeBtn === i && "active"}`}
              onClick={() => setActiveBtn(i)}
            >
              {service.title}
            </button>
          );
        })}

        {/* <button
          className={`service-btn ${activeBtn === 1 && "active"}`}
          onClick={() => setActiveBtn(1)}
        >
          MVP Development
        </button> */}
        {/* <button
          className={`service-btn ${activeBtn === 2 && "active"}`}
          onClick={() => setActiveBtn(2)}
        >
          Custom Web Development
        </button> */}
      </div>
      {servicesList[activeBtn]}
      <div className="arrows">
        <Arrow disabled={!activeBtn} onClick={forPrevArrow} />
        <Arrow
          right
          disabled={activeBtn === services.length - 1}
          onClick={forNextArrow}
        />
      </div>
    </section>
  );
}
