import React, { useRef, useState } from "react";
import "./UploadInput.css";
import {useTranslation} from "react-i18next";
// Style the Button component

const FileUploader = () => {
  const [fileName, setFileName] = useState("");

  // Create a reference to the hidden file input element
  const hiddenFileInput = useRef(null);

  const { t } = useTranslation();


  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    // handleFile(fileUploaded);
    setFileName(fileUploaded.name);
  };
  function deleteFile() {
    hiddenFileInput.current.type = "text";
    hiddenFileInput.current.type = "file";
    setFileName("");
  }
  return (
    <>
      <div className="dropDown_placeholder upload">
        <div>
          <p className="fileName">{fileName || t('contact.file_placeholder')}</p>
          {fileName ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={deleteFile}
            >
              <path
                d="M12 21.1426C17.0495 21.1426 21.1429 17.0493 21.1429 11.9998C21.1429 6.95033 17.0495 2.85693 12 2.85693C6.95057 2.85693 2.85718 6.95033 2.85718 11.9998C2.85718 17.0493 6.95057 21.1426 12 21.1426Z"
                stroke="#FEFEFE"
                strokeWidth="1.14286"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15.4287 8.57129L8.57153 15.4284M8.57153 8.57129L15.4287 15.4284L8.57153 8.57129Z"
                stroke="#FEFEFE"
                strokeWidth="1.14286"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : null}
        </div>
        <button type="button" className="uploadBtn" onClick={handleClick}>
          {t('contact.file_btn')}
        </button>
      </div>
      <input
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
};
export default FileUploader;
