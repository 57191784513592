import { useEffect, useState } from "react";
import "./FormSection.css";
import Api from "../../API";
import DropDown from "../../components/dropdown/DropDown";
import { budget } from "./budget-options";
import { timelines } from "./timelines-options";
import FileUploader from "../../components/upload-input/UploadInput";
import axios from "axios";
import {useTranslation} from "react-i18next";

export default function FormSection() {
  const [industries, setIndustries] = useState([]);
  const [isOk, setOk] = useState(false);
  const [activeOption, setActiveOption] = useState(0)

  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const api_lang = lang === "ru-RU" ? "ru" : lang === "ru" ? "ru" : "en"


  function submit(e) {
    e.preventDefault();
    console.dir(e.target);
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const formData = new FormData();
    formData.append("name", e.target[0].value);
    formData.append("email", e.target[1].value);
    formData.append(
      "industry",
      industries.find((elem) => elem.title === e.target[2].value).id
    );
    formData.append("budget", e.target[3].value);
    formData.append("timeline", e.target[4].value);
    formData.append("details", e.target[5].value);
    if (e.target[7].files.length) {
      formData.append("file", e.target[7].files[0]);
    }

    axios
      .post(`${process.env.REACT_APP_BASE_URL}en/api/application/`, formData, {
        headers,
      })
      .then(() => {
        e.target.reset();
        setOk(true);
        setTimeout(() => {
          setOk(false);
        }, 4000);
      })
      .catch((error) => console.dir(error));
  }

  useEffect(() => {
    Api.getData(`/${api_lang}/api/industries/`)
      .then((res) => setIndustries(res.data))
      .catch((e) => console.error(e));
  }, [api_lang]);

  const success = {
    en: `The application has been submitted successfully.\n Please wait for a reply in the email`,
    ru:`Заявка успешно отправлена.\n Пожалуйста, ожидайте ответа на электронную почту.`
  }

  return (
    <section id="contacts" className="form-section container">
      <div className={`goodRequest ${isOk && "showMsg"}`}>
        <b>
          {success[api_lang]}
        </b>
      </div>
      <h3>{t('contact.title')}</h3>
      <p>
        {t('contact.description')}</p>
      <form onSubmit={submit}>
        <div className="input">
          <input
            className="input_name"
            type="text"
            placeholder={t('contact.input_name')}
            required
          />
        </div>
        <div className="input">
          <input
            className="input_email"
            type="email"
            placeholder={t('contact.input_email')}
            required
          />
        </div>
        <DropDown
          className="industry"
          placeholder={t('contact.industry')}
          options={industries}
          visible={activeOption === 1}
          setActiveInput={setActiveOption}
          id={1}
        />
        <DropDown
          className="budget"
          placeholder={t('contact.budget')}
          options={budget}
          visible={activeOption === 2}          
          setActiveInput={setActiveOption}
          id={2}
        />
        <DropDown
          className="timelines"
          placeholder={t('contact.timelines')}
          options={timelines[api_lang]}
          visible={activeOption === 3}
          setActiveInput={setActiveOption}
          id={3}
        />
        <textarea placeholder={t('contact.project')} />
        <FileUploader />
        <button className="button">{t('contact.btn')}</button>
      </form>
    </section>
  );
}
