import "./Arrow.css";

export default function Arrow({ disabled, onClick, right = false }) {
  return (
    <button
      className={`arrow ${right && "right"} ${disabled && "disabled"}`}
      onClick={onClick}
    >
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M26.2854 33.1426L17.1426 23.9998L26.2854 14.8569"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  );
}
