import { useState } from "react";
import "./DropDown.css";

export default function DropDown({
  className = "",
  placeholder,
  options = [],
  visible,
  setActiveInput,
  id,
}) {
  const [activeOption, setActiveOption] = useState(0);
  // const [isVisible, setVisible] = useState(visible);

  function SetOption(i) {
    setActiveOption(i);
    setActiveInput(0)
  }
  function getVisibleOptions(e) {
    e.preventDefault();
    setActiveInput(id)
    if(visible){
      setActiveInput(0)
    }
    else{
      setActiveInput(id)
    }
  }
  return (
    <div className="dropDown">
      <div
        className={`dropDown_placeholder ${className}`}
        onClick={getVisibleOptions}
      >
        <input
          type="text"
          required
          placeholder={placeholder}
          value={
            activeOption
              ? options.find((option) => option.id === activeOption).title
              : ""
          }
          // style={{ display: "none" }}
        />
        <img
          src="/icons/arrow.svg"
          alt="arrow"
          style={{ transform: visible ? "rotate(90deg)" : "rotate(-90deg)" }}
        />
      </div>
      <div className="select" style={{ display: visible ? "flex" : "none" }}>
        {options.map((option) => (
          <div
            key={option.id}
            className="option"
            onClick={() => SetOption(option.id)}
          >
            {option.title}
          </div>
        ))}
      </div>
    </div>
  );
}
