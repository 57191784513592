import React from "react";
import project from "./../../assets/projects-img.svg";
import "./projectCard.css";
import {useTranslation} from "react-i18next";

const ProjectCard = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="projects__slide">
      <div className="projects__left">
        <h4>{data.title}</h4>
        <div className="projects__types">
          {data.industry.map((type) => (
            <div key={type.id} className="project_type">
              {type.title}
            </div>
          ))}
        </div>
        <p className="project_description">{data.description}</p>
        <div className="project_team">
          <b>{t('project_card.team')}</b>
          <div className="block-for-tablet">
            <ul>
              {data.team.map((man) => (
                <li key={man.id}>{man.title}</li>
              ))}
            </ul>
            <div className="projects__right tablet">
              <img src={data.image || project} alt="projectImage" />
            </div>
          </div>
        </div>
      </div>
      <div className="projects__right main">
        <img src={data.image || project} alt="projectImage" />
      </div>
    </div>
  );
};

export default ProjectCard;
