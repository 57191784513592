export const timelines = {
  en: [
    {
      id: 1,
      title: "< 3 Months",
    },
    {
      id: 2,
      title: "3 - 6 Months",
    },
    {
      id: 3,
      title: "6 - 9 Months",
    },
    {
      id: 4,
      title: "> 9 Months",
    },
  ],
  ru: [
    {
      id: 1,
      title: "< 3 месяцев",
    },
    {
      id: 2,
      title: "3 - 6 месяцев",
    },
    {
      id: 3,
      title: "6 - 9 месяцев",
    },
    {
      id: 4,
      title: "> 9 месяцев",
    },
  ],
};

